<template>
  <section class="mt-16 PersonalDigitalAccountList panel-section">
    <div class="d1">
      <div class="flex alignFlexEnd justifySpaceBetween">
        <div class="flex alignFlexEnd">
          <div>
            <label>查询条件</label>
            <el-select v-model="condition" placeholder="请选择" 
              @change="changeCondition">
              <el-option
                v-for="item in userConditionList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="mlr">
            <el-input
              :disabled="canBeInput"
              v-model.trim="formSearch[condition]"
              placeholder="选择查询条件后输入"
            ></el-input>
          </div>
        </div>
        <div>
          <el-button type="primary" @click="onSubmit">查询</el-button>
          <el-button @click="onReset">重置</el-button>
        </div>
      </div>

      <div class="add" @click="addAccount">
        <i class="el-icon-circle-plus-outline"></i>
        <span>新增</span>
      </div>
    </div>

    <Table
      ref="table"
      :baseData="baseData"
      :tableHeader="tableHeader"
      :currentPage="formSearch.pageNo"
      :total="total"
      :listLoading="listLoading"
      :isShowPagination="true"
      @handleCurrent="handleCurrent"
    >
      <el-table-column fixed="right" label="渠道用户账户" width="120">
        <template slot-scope="scope">
          <div class="opration check">
            <span @click="openAppUser(scope.row)">查看</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <div class="opration check">
            <span @click="openJoinDialog(scope.row)">对接管理</span>
            <span @click="openRecordDialog(scope.row)">查看</span>
            <span @click="editRecode(scope.row)">编辑</span>
          </div>
        </template>
      </el-table-column>
    </Table>

    <BaseInfoDialog :item="accountDialogInfo" />

    <TabDialog
      :item="joinDialogInfo"
      @update-page-view="updatePageView"
    ></TabDialog>
  </section>
</template>
<script>
import { mapMutations } from "vuex";
import API_ACCOUNT from "@/api/api_account";
import TabDialog from "@/components/customer/TabDialog";
//客户管理--客户备案
import dayjs from "dayjs";
import BaseInfoDialog from "@/components/customer/BaseInfoDialog.vue";
import { userConditionList } from "@/util/constant";

export default {
  name: "Filing",
  components: {
    TabDialog,
    BaseInfoDialog,
  },
  data() {
    return {
      userConditionList,
      baseData: [],
      total: 0,
      listLoading: true,
      tableHeader: this.tableHeader.customRecord.tableHeader,
      formSearch: {
        pageNo: 1,
        pageSize: 10,
        creditCode: undefined,
        name: undefined,
        appId: undefined,
        workbenchAccount: undefined,
      },
      accountDialogInfo: {
        isShow: false,
      },
      joinDialogInfo: {
        isShow: false,
      },
      condition: undefined,
      jumpNameMap: {
        Filing: "FilingAdd",
        Account: "AddAccount",
      },
    };
  },
  computed: {
    canBeInput: function () {
      return this.condition === undefined;
    },
  },
  methods: {
    ...mapMutations(["setAccountFillInfo"]),
    changeCondition() {
      this.formSearch.creditCode = undefined;
      this.formSearch.name = undefined;
      this.formSearch.appId = undefined;
      this.formSearch.workbenchAccount = undefined;
    },
    /**
     * 查询信息
     */
    onSubmit() {
      this.formSearch.pageNo = 1;
      this.getDigitalAccountList();
    },
    /**
     * 表单重置
     */
    onReset() {
      this.condition = undefined;
      this.changeCondition();
    },
    getDigitalAccountList() {
      API_ACCOUNT.getAccountFill(this.formSearch)
        .then(({ data }) => {
          if (data.code == 200 && data.data) {
            data.data.records.forEach((item, index) => {
              item.numid =
                index +
                1 +
                (this.formSearch.pageNo - 1) * this.formSearch.pageSize;
            });
            this.baseData = data.data.records || [];
          } else {
            this.baseData = [];
          }
          this.total = (data.data && data.data.total) || 0;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    /**
     * 切换页码
     */
    handleCurrent(val) {
      this.formSearch.pageNo = val;
      this.getDigitalAccountList();
    },
    editRecode(res) {
      this.setAccountFillInfo(res);
      this.$router.push({ name: "FilingAdd", query: { id: res.id } });
    },
    openAppUser(res) {
      this.$router.push({
        name: "ChannelAccount",
        query: {
          id: res.id,
          appId: res.appId,
          name: encodeURIComponent(res.name),
        },
      });
    },
    openJoinDialog(data) {
      this.joinDialogInfo = Object.assign(
        {
          isShow: true,
          type: "accountFill",
        },
        data
      );
    },
    openRecordDialog(data) {
      this.accountDialogInfo = Object.assign(
        {
          isShow: true,
          type: "accountFill",
        },
        data
      );
    },
    updatePageView() {
      this.getDigitalAccountList();
    },
    addAccount() {
      const routeName = this.$route.name;
      this.jumpNameMap[routeName] &&
        this.$router.push({ name: this.jumpNameMap[routeName] });
    },
  },
  created() {
    this.getDigitalAccountList();
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/_basePage.scss";
label {
  display: block;
  margin-bottom: 8px;
}
.d1 {
  padding: 32px;
}
.mlr {
  margin-right: 56px;
  margin-left: 12px;
}
.add {
  padding: 10px 16px;
  margin-top: 32px;
  gap: 8px;
  width: 84px;
  height: 40px;
  background: #ff9428;
  border-radius: 4px;
  font-family: "Microsoft YaHei UI";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  cursor: pointer;
}
</style>