<template>
  <section class="mt-16 panel-section">
    <el-tabs class="tabs" v-model="activeName" @tab-click="tabClick">
      <el-tab-pane label="客户备案" name="first">
        <UserFiling />
      </el-tab-pane>
      <el-tab-pane label="认证信息与审核" name="second">
        <AuthOrAudit />
      </el-tab-pane>
    </el-tabs>
  </section>
</template>
<script>
import UserFiling from "@/components/customer/UserFiling";
import AuthOrAudit from "@/components/customer/AuthOrAudit.vue";

let activeName = 'first';
export default {
  name: "QueryVue",
  components: {
    UserFiling,
    AuthOrAudit
  },
  data() {
    return {
      activeName: "first",
    };
  },
  methods: {
    tabClick() {
      activeName = this.activeName;
    }
  },
  created() {
    this.activeName = activeName;
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/_basePage.scss";

$primaryColor: #337cff;
.tabs {
  /deep/ .el-tabs__active-bar {
    height: 6px;
    background: $primaryColor;
    border-radius: 28px;
  }
  /deep/ .el-tabs__item.is-active {
    color: $primaryColor;
  }
  /deep/ .el-tabs__item {
    height: 72px;
    line-height: 72px;
  }
  /deep/ .el-tabs__nav-scroll {
    padding-left: 32px;
  }
}
</style>