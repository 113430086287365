<template>
  <section class="mt-16 PersonalDigitalAccountList panel-section">
    <div class="d1">
      <div class="flex alignFlexEnd justifySpaceBetween">
        <div class="flex alignFlexEnd">
          <div>
            <label>查询条件</label>
            <el-select
              v-model="condition"
              placeholder="请选择"
              @change="changeCondition"
            >
              <el-option
                v-for="item in userConditionList.slice(2)"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="mlr">
            <el-input
              :disabled="canBeInput"
              v-model.trim="formSearch[condition]"
              placeholder="选择查询条件后输入"
            ></el-input>
          </div>
          <div>
            <label>企业认证状态</label>
            <el-select
              v-model="formSearch.auditStatus"
              placeholder="请选择状态"
            >
              <el-option
                v-for="item in auditConditionList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div>
          <el-button type="primary" @click="onSubmit">查询</el-button>
          <el-button @click="onReset">重置</el-button>
        </div>
      </div>
    </div>

    <Table
      ref="table"
      :baseData="baseData"
      :tableHeader="tableHeader"
      :currentPage="pageNo"
      :total="total"
      :isShowPagination="true"
      @handleCurrent="handleCurrent"
      @onClickBtn="onClickBtn"
    >
      <el-table-column fixed="right" label="操作" width="120">
        <template slot-scope="scope">
          <div class="opration check">
            <span
              v-if="
                (scope.row.auditStatus == 1 || scope.row.auditStatus == 4) && scope.row.workbenchUserRole == 1
              "
              @click="gotoAudit(scope.row)"
              >认证审核</span
            >
          </div>
        </template>
      </el-table-column>
    </Table>

    <BaseInfoDialog :item="baseInfoData" />
  </section>
</template>
<script>
import { mapMutations } from "vuex";
import { userConditionList, auditConditionList } from "@/util/constant";
import BaseInfoDialog from "@/components/customer/BaseInfoDialog.vue";
import API_ACCOUNT from "@/api/api_account";

export default {
  name: "Filing",
  components: {
    BaseInfoDialog,
  },
  data() {
    return {
      userConditionList,
      auditConditionList,
      baseData: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      tableHeader: this.tableHeader.authOrAudit.tableHeader,
      formSearch: {
        appId: undefined,
        workbenchAccount: undefined,
        auditStatus: undefined,
      },
      condition: undefined,
      baseInfoData: {
        isShow: false,
      },
    };
  },
  computed: {
    canBeInput: function () {
      return this.condition === undefined;
    },
  },
  methods: {
    /**
     * 查询信息
     */
    onSubmit() {
      this.pageNo = 1;
      this.getDataList();
    },
    changeCondition() {
      this.formSearch.appId = undefined;
      this.formSearch.workbenchAccount = undefined;
    },
    /**
     * 表单重置
     */
    onReset() {
      this.condition = undefined;
      this.changeCondition();
      this.formSearch.auditStatus = undefined;
    },
    /**
     * 切换页码
     */
    handleCurrent(val) {
      this.pageNo = val;
      this.getDataList();
    },
    gotoAudit(res) {
      this.$router.push({
        name: "EnterpriseAuth",
        query: { id: res.id, isAuth: 1 },
      });
    },
    getDataList() {
      API_ACCOUNT.indentifyAuthInfo(
        this.pageSize,
        this.pageNo,
        this.formSearch
      ).then((res = {}) => {
        const {
          data: { data: { records = [], total } = {}, code, msg = "" } = {},
        } = res;
        if (code == 200 && records && records.length > 0) {
          this.baseData = records;
          this.total = total;
        } else {
          this.baseData = [];
        }
      });
    },
    onClickBtn(res) {
      const buttonType = res.buttonType;
      if (buttonType == "work") {
        this.baseInfoData = Object.assign(
          {
            isShow: true,
            type: "workAccount",
            id: res.workbenchUserId
          }
        );
      } else if (buttonType == "realName") {
        this.$router.push({ name: "RealNameAuth", query: {
          id: res.workbenchUserId
        }});
      } else if (buttonType == "company") {
        this.$router.push({
          name: "EnterpriseAuth",
          query: { id: res.id },
        });
      }
    },
  },
  created() {
    this.pageNo = 1;
    this.onReset();
    this.getDataList();
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/_basePage.scss";
label {
  display: block;
  margin-bottom: 8px;
}
.d1 {
  padding: 32px;
}
.mlr {
  margin-right: 56px;
  margin-left: 12px;
}
.add {
  padding: 10px 16px;
  margin-top: 32px;
  gap: 8px;
  width: 84px;
  height: 40px;
  background: #ff9428;
  border-radius: 4px;
  font-family: "Microsoft YaHei UI";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  cursor: pointer;
}
</style>