<template>
  <div>
    <el-dialog
      :visible.sync="isShow"
      :before-close="onClickClose"
      :close-on-click-modal="false"
      :title="title"
      :width="width"
      class="panel-dialog"
    >
      <div>
        <el-tabs v-model="tabActiveName" @tab-click="tabChange">
          <template v-if="item.type === 'accountFill'">
            <el-tab-pane label="对接状态" name="1">
              <ul>
                <li
                  class="li1 flex alignCenter"
                  v-for="(item, index) in detailMap['status']"
                  :key="`detailList${index}`"
                >
                  <label>{{ item.name }}</label>
                  <el-radio-group
                    v-if="item.status === 'joinStatus'"
                    v-model="joinRadio"
                  >
                    <el-radio :label="true">接入</el-radio>
                    <el-radio :label="false">断开</el-radio>
                  </el-radio-group>
                  <span v-else class="content">{{ item.value }}</span>
                </li>
              </ul>
            </el-tab-pane>
            <el-tab-pane label="对接验签" name="2">
              <ul>
                <li
                  class="li1 flex alignCenter"
                  v-for="(item, index) in detailMap['sign']"
                  :key="`detailList${index}`"
                >
                  <label>{{ item.name }}</label>
                  <span
                    v-if="item.status === 'priKey' || item.status === 'pubKey'"
                    class="check"
                    @click="operateKey(item.status, item.value)"
                    >{{ item.txt }}</span
                  >
                  <span
                    v-else-if="item.status == 'download'"
                    class="check"
                    @click="downloadCer"
                    >{{ item.txt }}</span
                  >
                  <span v-else class="content">{{ item.value }}</span>
                </li>
              </ul>
            </el-tab-pane>
          </template>
        </el-tabs>
      </div>
      <div class="footer" slot="footer">
        <el-button plain size="small" class="btn" @click="onClickClose"
          >关闭</el-button
        >
        <el-button
          v-show="tabActiveName == 1"
          plain
          size="small"
          class="btn color_1"
          @click="save"
          >保存修改</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import API_ACCOUNT from "@/api/api_account";
import { exportExcel } from "@/util/downLoad";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      isShow: false,
      title: "",
      tabActiveName: "1",
      width: "50%",
      detailMap: {},
      joinRadio: false,
    };
  },
  watch: {
    item(newVal) {
      if (newVal) {
        this.setDialogInfo(newVal);
      }
    },
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    ...mapGetters(["deviceInfo"]),
  },
  methods: {
    submit() {
      this.$emit("submit", "test");
    },
    onClickClose() {
      this.isShow = false;
    },
    setDialogInfo(data) {
      this.isShow = data.isShow;
      if (data.type === "accountFill") {
        this.title = "对接管理";
        this.width = "560px";
        this.tabActiveName = "1";

        this.setFillInfo(data);
      }
    },
    setFillInfo(data) {
      const comColumn = [
        { name: "客户编号", value: data.numid || "" },
        { name: "客户名称", value: data.name || "" },
      ];
      this.joinRadio = data.accessStatus;
      this.detailMap["status"] = comColumn.concat([
        { name: "渠道编号", value: data.appId || "" },
        { name: "对接状态", status: "joinStatus" },
      ]);
      this.detailMap["sign"] = comColumn.concat([
        {
          name: "验签公钥",
          txt: "复制",
          value: data.publicKey,
          status: "pubKey",
        },
        {
          name: "验签私钥",
          txt: "生成",
          value: data.privateKey,
          status: "priKey",
        },
        {
          name: "服务通行证",
          txt: "下载",
          status: "download",
        },
      ]);
    },
    tabChange(tab, event) {},
    operateKey(type, val) {
      let message = "";
      if (type === "pubKey") {
        message = "复制成功~";
      } else if (type === "priKey") {
        message = "生成成功，已复制到剪贴板~";
      }
      this.$copyText(val).then(
        (e) => {
          this.$message({
            message,
            type: "success",
          });
        },
        (e) => {}
      );
    },
    save() {
      if (this.joinRadio == this.item.accessStatus) {
        this.$message.warning({
          message: "未更改对接状态，无法提交",
        });
        return;
      }
      this.$confirm(`确定更新对接信息吗？`, "提示", {
        type: "warning",
      })
        .then(() => {
          API_ACCOUNT.setJoinStatus({
            id: this.item.id,
            accessStatus: this.joinRadio,
          }).then(({ data }) => {
            if (data.code == 200) {
              this.$message({
                message: "更新成功~",
                type: "success",
              });
              this.onClickClose();

              this.$emit("update-page-view", {});
            } else {
              this.$message.error({
                message: data.msg || this.$CommonMsg["networkErr"],
              });
            }
          });
        })
        .catch((err) => {});
    },
    downloadCer() {
      exportExcel({
        // filename: `服务通行证`,
        // url: `${this.deviceInfo.baseUrl}/v1/admin/shop/download/${this.item.id}`,
        // fileType: ".txt",
        // contentType: "text/plain;charset=UTF-8",
        filename: `工作台服务通行证`,
        url: `${this.deviceInfo.baseUrl}/v1/admin/shop/download/${this.item.id}`,
        fileType: ".zip",
        contentType: "application/force-download;charset=UTF-8",
        blobType: "application/zip"
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/_baseDialog.scss";

.export {
  margin-left: 12px;
  cursor: pointer;
  font-family: "Microsoft YaHei UI";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  display: flex;
  align-items: center;
  text-align: right;
  text-decoration-line: underline;

  /* color-blue */

  color: #337cff;
}
.w200 {
  width: 200px !important;
  height: 32px !important;
}
.li1 {
  height: 48px;
  border-bottom: 1px solid #e3e5e8;
  > label {
    width: 120px;
    font-family: "Microsoft YaHei UI";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #333a47;
  }
  .content {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #616875;
  }
}

.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.color_1 {
  background: #337cff !important;
  color: #fff !important;
}

.text_1 {
  color: #333333;
}

.text_2 {
  color: #999999;
}

.dialog_title {
  font-size: 14px;
  font-family: "MicrosoftYaHei";
  line-height: 19px;
  color: rgba(51, 51, 51, 1);
  margin-bottom: 8px;
}

/deep/ .el-tabs__item {
  height: 40px !important;
}

/deep/ .el-tabs__nav-scroll {
  padding-left: 0 !important;
}
</style>
